.componentRef {
  position: relative;
  height: 100%;
  width: 100%;

}

.displayPreviewContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.containerEvent {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
  padding: 8px;
}

.event {
  position: absolute;
  padding: 2px;
  height: 100%;
  width: 50%;
  max-width: 450px;
}

.selected {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 4;
  opacity: 1;
  transform: translateX(0);
  transition: 0.5s; //ease-in-out;
}

.hidden {
  opacity: 0;
  //transition: 0.5s ease-in-out;
}

.right {
  transform: translateX(-100%);
}

.left {
  transform: translateX(100%);
}

.progressBar {
  width: 100%;

}

.boxtimeline {
  position: relative;
  overflow: auto;
  background-color: whitesmoke;
  height: 55%;
  // margin-top:-8%;
  z-index: -1;
  padding: 15px;
}

.boxtimelineTitle {
  font-size: 25px;
  text-align: end;
}

.boxtimelineDescription {
  font-size: 17px;
  text-align: justify;
}

.pImg {
  height: 35%;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  overflow: hidden;
  display: flex;
  position: relative;
  // background-color:#32c7ae;
}
.pImg2 {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  z-index: 2;
  background-color: #32c7ae;
}

.imgWhiteBackground {
  height: 90%;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-color: whitesmoke;
}

// @media (max-width: 1194px) {
//   .boxtimeline {
//     margin-top:-11%;
//   }
// }

// @media (max-width: 754px) {
//   .boxtimeline {
//       margin-top:-20px;
//   }
// }

@media (max-width: 731px) {
  .containerEvent {
    height: 100%;
    width: 100%;
  }

  .event {
    width: 60%;
  }

  .boxtimeline {
    width: 100%;
    // margin-top:-12%;
  }

  .selected {
    width: 100%;
  }

  .pImg {
    width: 100%;
  }
  .pImg2 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .containerEvent {
    height: 100%;
    width: 100%;
  }

  .boxtimeline {
    width: 100%;
  }

  .boxtimelineTitle {
    font-size: 16px;
  }

  .boxtimelineDescription {
    font-size: 13px;
    text-align: left;
  }

  .event {
    width: 100%;
  }

  .pImg {
    width: 100%;
  }
  .pImg2 {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .event {
    width: 100%;
  }
}

@media (max-width: 300px) {
  .containerEvent {
    height: 100%;
    width: 100%;
  }

  .boxtimeline {
    width: 100%;
  }

  .event {
    width: 99%;
  }
}

.eventsContent {
  color: white;
  height: auto;
}

.dateEstory {
  position: absolute;
  height: auto;
  width: auto;
  color: white;
  background-color: #208775;
  z-index: 4;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 6px;

}

.dateEstoryGenerated {
  font-size: 20px;
  font: Source Sans Pro;
  font-weight: 300;
}

.noEvent {
  color: white;
  font-size: 50px;
}

.dotDateContainer {
  position: absolute;
  background-color: #208775;
  border-radius: 5px;
  height: 30px;
  width: auto;
  display: flex;
  justify-content: center;
  z-index: 2;
  padding: 3px;
  align-items: center;
  color: white;
}

.bgClickActionContainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bgClickActionLeft {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
}

.bgClickActionRight {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
}
