.cropper{
  cursor:move;
  z-index:1000;
  pointer-events: auto;
}
.cropper2{
  cursor:none;
  pointer-events: none;
}
.cropper2 img{
  width: 100%;
}
.componentRef {
  position: relative;
  height: 100%;
  width:100%;
}

.displayPreviewContainer {
  position: relative;
  height:100%;
  width: 100%;
}
.containerEvent {
  position: relative;
  height: 100%;
  width:100%;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}

.event {
  position: absolute;
  padding: 2px;
  height: 100%;
  width: 50%;
}

.selected {
  position: absolute;
  height:100%;
  width:60%;
  z-index: 4;
  opacity: 1;
  transform: translateX(0);
  pointer-events:none;
  transition: 0.5s;
}
.hidden {
  opacity: 0;
  pointer-events:auto;
}

.right {
  transform: translateX(-100%);
}

.left {
  transform: translateX(100%);
}

.progressBar {
  width: 100%;
}

.boxtimeline {
  position: relative;
  overflow: auto;
  background-color: whitesmoke;
  margin-top: -25px;
  height: 65%;
  z-index: -1;
  padding: 15px;
}

.boxtimelineTitle {
  font-size: 20px;
  text-align: end;
}

.boxtimelineDescription {
  font-size: 14px;
  text-align: justify;
}

.pImg {
  height: 32%;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  overflow: hidden;
  display:flex;
  position:relative;
  background-color:#32c7ae;
  pointer-events: auto;
}
.pImg2 {
  width: 100%;
  position: relative;
  z-index:1; 
  object-fit: cover;
  background-color:#32c7ae;
  pointer-events: auto;

}

.options {
  position: absolute;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 4px;
  padding-right: 5px;
  visibility: hidden;
  z-index: 1;
}

.options2{
  display:flex; 
  pointer-events: auto;
}
.selected:hover {
  .options {
    visibility: visible;
  }
}

.modify {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #98431B;
  border-radius: 5px;
  font: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 400;
  margin-left:3px;
  color: white;
  cursor: pointer;
  pointer-events: auto;
}
.modify2 {
  position: relative;
  width: 20px;
  height: 24px;
  background-color: #98431B;
  border-radius: 5px;
  font: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 400;
  margin-left:3px;
  color: white;
  cursor: pointer;
  pointer-events: auto;
}
.modify3 {
  position: relative;
  width: 90px;
  height: 24px;
  background-color: #208775;
  border-radius: 5px;
  font: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 400;
  margin-left: 110px;
  color: white;
  cursor: pointer;
  pointer-events: auto;
}
.dragImage{
  color:white;
  background-color: rgb(78, 77, 77);
  border-radius: 7px;
  // opacity:90% !important;
  margin-top:5px;
  padding: 3px;
  font-size: x-small;
  margin-right: 65px;
  z-index:1;
}
.delete {
  position:relative;
  width: 25px;
  height: 24px;
  background-color: #da1f1f;
  border-radius: 5px;
  color: white;
  cursor:pointer;
  pointer-events: auto;
}

.trash {
  height : 20px;
  margin-bottom:4px;
}

@media (max-width: 1360px) {
  .dragImage {
     margin-right:50px;
     margin-top:9px;
  }
 }
 @media (max-width: 420px) {
  .dragImage {
     margin-right:0px;
     margin-left:25px;
  }
 }
 @media (max-width: 790px) {
  .dragImage {
     margin-right:0px;
     margin-left:60px;
     margin-top:15px;
  }
 }
@media (max-width: 1194px) {
 .boxtimeline {
    margin-top:-10%;
 }
}

@media (max-width: 754px) {
  .boxtimeline {
     margin-top:-20px;
  }
 }
 

@media (max-width: 731px) {
  .containerEvent {
    height:100%;
    width:100%;
  }

  .event {
    width:50%;
  }

  .boxtimeline {
    width: 100%;
    margin-top:-12%;
  }

  .selected {
    width:100%;
  }

  .pImg {
    width: 100%;
  }
  .pImg2 {
    width:100%;
  }
}

@media (max-width: 500px) {

  .containerEvent {
    height:100%;
    width:100%;
  }

  .boxtimeline {
    width: 100%;
  }

  .event {
    width:50%;
  }

  .pImg {
    width: 100%;
  }
  .pImg2 {
    width: 100%;
  }
}

@media (max-width: 300px) {

  .containerEvent {
    height:100%;
    width:100%;
  }

  .boxtimeline {
    width: 100%;
  }

  .event {
    width:70%;
  }
}

.eventsContent {
  color: white;
  height: auto;
}

.dateEstory {
  position: absolute;
  height:30px;
  width: auto;
  color: white;
  background-color: #208775;
  z-index: 2;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 6px;
}

.dateEstoryGenerated {
  font-size: 15px;
  font: Source Sans Pro;
  font-weight: 300;
}

.dotDate {
  font-size: 14px;
}

.noEvent {
  color: white;
  font-size: 50px;
}

.dotDateContainer {
  position: absolute;
  background-color: #208775;
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  z-index:2;
  padding:3px;
  align-items: center;
  color: white;
}

.bgClickActionContainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.bgClickActionLeft {
  width: 50%;
  height:100%;
  background-color: rgba(255, 255, 255, 0);
}

.bgClickActionRight {
  width: 50%;
  height:100%;
  background-color: rgba(255, 255, 255, 0);
}